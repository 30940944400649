
import axios from 'axios'
import {io} from "socket.io-client";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
if(sessionStorage.getItem("data")){
    const data = JSON.parse(sessionStorage.getItem("data"));
    axios.defaults.headers.common = {'Authorization': `bearer ${data.token}`}
}

const getSocket = query => io(process.env.REACT_APP_API_SOCKET_URL, {query});

export default axios;

export {getSocket}; 