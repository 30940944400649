//import useState hook to create menu collapse state
import React, { useEffect, useMemo, useState } from "react";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { BsSearch } from "react-icons/bs";
import { FaHandsHelping } from "react-icons/fa";
import { MdOutlineAssessment } from "react-icons/md";
import { FaSnowboarding } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";


//import icons from react icons
import {
  FaHome,
  FaUserNurse,
  FaCheck,
  FaMailBulk,
} from "react-icons/fa";
import { IoStopwatchOutline } from "react-icons/io5";
import {
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiSettings,
} from "react-icons/fi";
import { NavLink as Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import styled from "styled-components";
import { userRoles } from "../toolkit Store/Constants";
import { logout } from "../toolkit Store/Reducer/DataReducer";

export const NavLink = styled(Link)`
  color: #ffffff;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 5px 12px;
  height: 100%;
  cursor: pointer;
  &.active {
    color: white;
    background-color: #32a852;
  }
`;

const Sidebar = props => {
  const [menuCollapse, setmenuCollapse] = useState(true)
  const [show, setShow] = useState(false)
  const StoreData = useSelector((state) => state.data)
  useEffect(() => {
    setInterval(() => {
      setShow(true)
    }, 2000)
  }, [])
  const dispatch = useDispatch()
  const menuLinkClick = () => {
    setmenuCollapse(true)
  }
  const handleMouseEnter = () => {
    setmenuCollapse(false);
  };

  const handleMouseLeave = () => {
    setmenuCollapse(true);
  };
  const permissions = useMemo(() => StoreData?.Role?.permissions, [StoreData?.Role?.permissions]) || []
  const { User: user, Hospital, Loading } = StoreData
  return (
    <>
      <div id="sidebar" onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className="closemenu" style={{display : "none"}}  onClick={() => setmenuCollapse(!menuCollapse)}>
          {menuCollapse ? (
            <FiArrowRightCircle />
          ) : (
            <FiArrowLeftCircle />
          )}
        </div>

        <ProSidebar collapsed={menuCollapse} className={ menuCollapse ? 'd-none d-md-block mt-3' : "mt-3"}  >
          <SidebarHeader>
            <div className="logotext mt-5"></div>
          </SidebarHeader>
          <SidebarContent>
          { !Loading &&
            <Menu iconShape="square">
              <MenuItem icon={<FaHome className="text-white" />}>
                <NavLink to="dashboard" onClick={menuLinkClick}>
                  Dashboard
                </NavLink>
              </MenuItem>
              {user.role !== userRoles.pr &&
              <>
                {user.role === userRoles.admin &&
              <MenuItem icon={<FaUserNurse className="text-white" />}>
              <NavLink to="users" onClick={menuLinkClick}>
                Users
              </NavLink>
            </MenuItem>
              }
              <MenuItem icon={<BsSearch  className="text-white" />}>
              <NavLink to="nat" onClick={menuLinkClick}>
                NAT
              </NavLink>
            </MenuItem>
            </>
          }
              {Hospital?.modules?.includes('pr') &&
              <MenuItem icon={<FaHandsHelping  className="text-white" />}>
              <NavLink to="pr" onClick={menuLinkClick}>
                PR
              </NavLink>
            </MenuItem>
          }
              {user.role !== userRoles.pr &&
              <>
             {
              Hospital?.modules?.includes('Therapist') && 
              <>
              <MenuItem icon={<FaSnowboarding  className="text-white" />}>
              <NavLink to="sa" onClick={menuLinkClick}>
                Session Approvals
              </NavLink>
            </MenuItem>
              <MenuItem icon={<MdOutlineAssessment  className="text-white" />}>
              <NavLink to="utilization" onClick={menuLinkClick}>
              Utilytics
              </NavLink>
            </MenuItem>
              <MenuItem icon={<FaHandshake  className="text-white" />}>
              <NavLink to="sessions" onClick={menuLinkClick}>
                Sessions
              </NavLink>
            </MenuItem>
            </>
            }
                </>
              }
              {user.role !== userRoles.pr &&
                <MenuItem icon={<FaCheck className="text-white" />}>
                  <NavLink to="attendance" onClick={menuLinkClick}>
                    Attendance
                  </NavLink>
                </MenuItem>
              }
              {
                (user.role !== userRoles.pr) &&
                <MenuItem icon={<FaMailBulk className="text-white" />}>
                  <NavLink to="messages" onClick={menuLinkClick}>
                    Messages
                  </NavLink>
                </MenuItem>
              }
              {(user.role === userRoles.admin) &&
                <MenuItem icon={<FiSettings className="text-white" />}>
                  <NavLink to="settings" onClick={menuLinkClick}>
                    Settings
                  </NavLink>
                </MenuItem>
              }
            </Menu>
          }
          </SidebarContent>
          {/* <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem
                icon={<FiLogOut className="text-white" />}
                onClick={() => dispatch(logout())}
              >
                Logout
              </MenuItem>
            </Menu>
          </SidebarFooter> */}
        </ProSidebar>
      </div>
    </>
  )




}


export default Sidebar
