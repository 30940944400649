import React, { useState } from "react";
import "./../css/App.css";
import axios from "../config/server.config";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol
} from "mdb-react-ui-kit";
import { Box, Button, TextField } from "@mui/material";
import '../css/Login.css'
import { Form } from "react-bootstrap";
import { setUserToken } from "../toolkit Store/Reducer/DataReducer";
import io from 'socket.io-client'

const Login  = () => {
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const dispatch = useDispatch()

const onLogin = async (e) => {
  try{
    e.preventDefault()
    const LoginData = await axios.post("users/login", { email, password })
    const data = LoginData.data
    toastr.options = {
      positionClass: "toast-top-full-width",
      hideDuration: 300,
      timeOut: 3000,
    };
    if(data.status){
      toastr.clear();
      toastr.info("Login Successfully")
      // socket.connect()
   
      
      dispatch(setUserToken(data.data))
    }else{
      toastr.clear();
      setTimeout(() => toastr.error("Unknwon Request Status"), 300);
    }
        
  }
  catch(err){
    // console.log("Error", err.response.data);
    toastr.error(err.response.data.message)
  }
}

  return(
    <div className="login-wrapper">
        <MDBContainer className="m-auto">
          <MDBCard className="bg-dark-n">
            <MDBRow className="g-0">
              <MDBCol md="6">
                <MDBCardImage
                  src="login.png"
                  className=" rounded-start w-100 h-100"
                />
              </MDBCol>

              <MDBCol md="6">
                <MDBCardBody className="d-flex flex-column">
                  <div className="d-flex flex-row mt-2">
                    <MDBCardImage
                      src="logo-rated.png"
                      className="h-10"
                    ></MDBCardImage>
                  </div>
                    <Box
                      marginTop={7}
                      sx={{
                        '& .MuiTextField-root': { m: 2, width: '95%' },
                      }}
                    >
                  <div>
                    <Form onSubmit={onLogin}>
                        <TextField 
                          className="login-text"
                          label="Email"
                          type={'email'}
                          variant="filled"
                          color="secondary"
                          autoFocus
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                          className="login-text"
                          label="Password"
                          type="password"
                          variant="filled"
                          color="secondary"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}

                        />
                      <Button type="submit" className="login-btn" variant="contained" size="large">Login</Button>  
                    </Form>
                  </div>
                
                </Box>
                  
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBContainer>
    </div>
  )
}


export default Login;
