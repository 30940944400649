/* eslint-disable default-case */
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios, { getSocket } from "../config/server.config";
import ReactLoading from "react-loading";
import { logout } from "../toolkit Store/Reducer/DataReducer";
import "../css/App.css";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBBadge,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBBtnGroup,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Badge, Modal, } from "react-bootstrap";
//import react pro sidebar components
import { ProSidebar } from "react-pro-sidebar";
import {
  FiLogOut
} from "react-icons/fi";
//import icons from react icons
import { FaBell, FaEyeSlash, FaCheck, FaUndo, FaUser } from "react-icons/fa";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import moment from 'moment-timezone'
import {createTherapist, updateTherapist , deleteTherapist, acceptRequest, addRealtime, createNurse, createPatient, deleteNurse, deletePatient, holidayResponse, leaveResponse, rejectRequest, removeRealtime, setAllData, setUser, updateNurse, updatePatient, updateSettings, readRealtime } from "../toolkit Store/Reducer/DataReducer";
import { requestStatus, userRoles } from "../toolkit Store/Constants";
import hospitalLogo from '../Assets/img/logo.gif'

toastr.options = {
  positionClass: "toast-top-full-width",
  hideDuration: 3000,
  timeOut: 3000,
};

export const NavLink = styled(Link)`
  color: #ffffff;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 5px 15px;
  height: 100%;
  curr: pointer;
  // border-radius: 10px;
  &.active {
    color: white;
    border-bottom: 4px solid white;
    // background-color: #003087;
  }
`;
export const NavBar = styled(MDBNavbar)`
  background-color: #00A658 !important;
`;
export const NavItem = styled(MDBNavbarItem)`
  padding: 5px;
`;
export const NavBrand = styled(MDBNavbarBrand)`
  color: white;
  height: 60px;
  width: 135px;
  border-radius : 15px;
  font: inherit;
  font-size: 20px;
`;

const toastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}


const Navbar = () => {
  const [page, setPage] = useState({ isLoading: true, menuCollapse: true, notifyCollapse: false })
  const [modal, setModal] = useState({ title: '', content: <></>, badge: '' })
  const dispatch = useDispatch()
  const StoreData = useSelector((state) => state.data)
  const permissions = useMemo(() => StoreData?.Role?.permissions, [StoreData?.Role?.permissions]) || []
  const hospitalId = useMemo(() => StoreData.Settings.hospitalId, [StoreData.Settings.hospitalId])
  const User = useMemo(() => StoreData?.User, [StoreData?.User])
  const role = useMemo(() => StoreData?.User?.role, [StoreData?.User?.role])
  const logo = useMemo(() => StoreData?.Hospital?.logo, [StoreData?.Hospital?.logo])
  const socket = useMemo(() => getSocket({ userId: StoreData.User.userId ? StoreData.User.userId : JSON.parse(sessionStorage.getItem('data')).userData.userId }), [StoreData.User.userId])
  const {Loading, Hospital} = StoreData;
  useEffect(() => {
    socket.connect()

    return () => {
      socket.disconnect()
      console.log('Socket Disconnected');
    }
  }, [])

  const getData = async () => {
    try {
      const response = await axios.get("admin/allData");
      
      if (sessionStorage.getItem("data")) {
        const Tokendata = JSON.parse(sessionStorage.getItem("data"));
        dispatch(setUser(Tokendata.userData));
      }
      
      moment.tz.setDefault(response.data.data.Settings?.timezone ?? 'Asia/Karachi');
      dispatch(setAllData(response.data.data));
      setPage({ ...page, isLoading: false });
    } catch (err) {
      toastr.error(err.response?.data.message || 'An error occurred', toastOption);
      setPage({ ...page, isLoading: false });
    }
  };
  useEffect(() => {
    (role !== "Fleet Manager") ? getData() : console.log('fleet manager');
  }, []);
  

  useEffect(() => {
    // Socket Request-Response
    const addSocketEventListeners = () => {
      socket.on(`createRealtime${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(addRealtime(response.data))
      })
      socket.on(`rejectResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(rejectRequest(response.data._id))
      })
      // Nurse
      socket.on(`createNurseResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(createNurse(response.data.nurse))
      })
      socket.on(`updateNurseResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(updateNurse(response.data.nurse))
      })
      socket.on(`deleteNurseResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(deleteNurse(response.data.nurse))
      })
      // Leave
      socket.on(`leaveResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(leaveResponse(response.data.leave))
      })
      // Rota
      socket.on(`assignRotaResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
      })
      // Patient
      socket.on(`createPatientResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(createPatient(response.data.patient))
      })
      socket.on(`updatePatientResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(updatePatient(response.data.patient))
      })
      socket.on(`deletePatientResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(deletePatient(response.data.patient._id))
      })
      // Holiday
      socket.on(`holidayResponse${hospitalId}`, (response) => {
        if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
          toastr.remove()
          toastr.info(response.message)
        }
        dispatch(acceptRequest(response.data.requestId))
        dispatch(holidayResponse(response.data.holidays))
      })
    }
    if (socket.connected) {
      addSocketEventListeners();
    } else {
      // Listen for the connection event and then add event listeners
      socket.on("connect", () => {
        addSocketEventListeners();
      });
    }

  }, [socket, hospitalId, role])


  useEffect(() => {
    // Socket Admin
 const addSocketEventListeners = () =>  { 
  socket.on(`newStaff${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(createNurse(response.data))
    })
  socket.on(`newTherapist${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(createTherapist(response.data))
    })
    socket.on(`updateStaff${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(updateNurse(response.data))
    })
    socket.on(`updateTherapist${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(updateTherapist(response.data))
    })
    socket.on(`deleteStaff${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(deleteNurse(response.data))
    })
    socket.on(`deleteTherapist${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(deleteTherapist(response.data))
    })
    socket.on(`leaveAdmin${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(leaveResponse(response.data))
    })
    socket.on(`assignRota${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
    })
    socket.on(`createPatient${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(createPatient(response.data))
    })
    socket.on(`updatePatient${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(updatePatient(response.data))
    })
    socket.on(`deletePatient${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(deletePatient(response.data._id))
    })
    socket.on(`holidayAdmin${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      dispatch(holidayResponse(response.data))
    })
    socket.on(`SettingsUpdated${hospitalId}`, (response) => {
      if (role === 'Admin' || role === 'Manager' || role === 'Approver') {
        toastr.remove()
        toastr.info(response.message)
      }
      moment.tz.setDefault(response.data?.timezone ?? 'Asia/Karachi')
      dispatch(updateSettings(response.data))
    })
}

if (socket.connected) {
  addSocketEventListeners();
} else {
  // Listen for the connection event and then add event listeners
  socket.on("connect", () => {
    addSocketEventListeners();
  });
}
  }, [socket, hospitalId, role])


  const menuIconClick = () => {
    setPage({ ...page, menuCollapse: !page.menuCollapse })
  };

  const approve = async (data) => {
    try {
      const METHOD = data.method
      var response;
      if (METHOD === 'POST') {
        response = await axios.post(data.request.substr(4, data.request.length), {
          ...data,
          status: requestStatus.approved
        })
      } else if (METHOD === 'PATCH') {
        response = await axios.patch(data.request.substr(4, data.request.length), {
          ...data,
          status: requestStatus.approved
        })
      }
      else if (METHOD === 'DELETE') {
        response = await axios.delete(data.request.substr(4, data.request.length), { data })
      }
      else {
        return toastr.error('Unknown Request!')
      }

      // toastr.info(response.data.message)

    } catch (err) {
      toastr.remove();
      toastr.error(err.response.data.message)
      console.log(err);
    }
  }

  const reject = async (data) => {
    try {
      const response = await axios.patch('request/reject', {
        _id: data._id,
        status: requestStatus.rejected
      })

    } catch (err) {
      toastr.error(err.response.data.message)
    }
  };

  const close = async (request) => {
    try {
      const response = await axios.delete(`request/${request._id}`)
      toastr.remove();
      toastr.info(response.data.message)
      dispatch(removeRealtime(response.data.data))

    } catch (err) {
      toastr.remove();
      toastr.error(err.response.data.message)
    }
  };

  const detail = (detail) => {
    let { Nurses, Patients, Leaves, Holidays, Therapists } = StoreData
    let allStaff = [...Nurses, ...Therapists]
    let title = "";
    let content = "";
    let selected = [];
    let needRows = [];

    let nurseColumns = [
      { name: "Nurse Code", path: "code" },
      { name: "Full Name", path: "name" },
      { name: "Designation", path: "designation" },
      { name: "Address", path: "address" },
      { name: "Mobile", path: "mobile" },
      { name: "Country", path: "country" },
      { name: "Experience", path: "experience" },
      { name: "JoinDate", path: "joiningDate" },
      { name: "EndingDate", path: "endingDate" },
      { name: "Speciality", path: "speciality" },
      { name: "Basic Allowances", path: "basicAllowance" },
      { name: "Housing Allowances", path: "housingAllowance" },
      { name: "Other Allowances", path: "otherAllowance" },
    ];
    let NurseLeaveColumns = [
      { name: "Nurse Name", path: "nurseName" },
      { name: "Code", path: "code" },
      { name: "Designation", path: "designation" },
      { name: "Type", path: "type" },
      { name: "From", path: "from" },
      { name: "To", path: "to" },
    ]
    let patientColumns = [
      { name: "Patient Code", path: "code" },
      { name: "Full Name", path: "name" },
      { name: "Address", path: "address" },
      { name: "Mobile", path: "mobile" },
      { name: "Level", path: "level" },
    ];
    let roasterColumns = [
      { name: "Nurse Code", path: "code" },
      { name: "Date", path: "date" },
      { name: "Emp Name", path: "nurseName" },
      { name: "Designation", path: "designation" },
      { name: "Duty Start", path: "dutyStart" },
      { name: "Duty End", path: "dutyEnd" },
      { name: 'Shift', path: 'shift' },
      { name: "Hour", path: "hour" },
    ];
    let holidayColumns = [
      { name: "Type", path: "type" },
      { name: "Year", path: "year" },
      { name: "Month", path: "month" },
      { name: "Day", path: "day" },
    ];
  // mark request as read
    const markRequestasRead = async () => {
      const response = await axios.patch('request/read', { id: detail._id })
      dispatch(readRealtime(response?.data?.data?._id))
    }
    markRequestasRead()
    let LeaveData, Nurse, Leave, Patient, PatientData, updatedNursesData, HolidayData, Holiday
    switch (detail.title) {
      case "Creating Staff":
      case "Creating Nurse":
        updatedNursesData = detail.data;
        title = detail.title;
        content =
          (
            <MDBTable responsive>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light">
                  {nurseColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow2" key={moment().get() * Math.random * 121}>
                  {nurseColumns.map(({ path, name }) => (
                    <td key={path}>{updatedNursesData[path]}</td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )

        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Updating Nurse":
      case "Updating Staff":
        updatedNursesData = detail.data;
        allStaff.map((nurse, key) => {
          if (nurse._id.includes(updatedNursesData.nurseId)) {
            updatedNursesData = nurse;

          }
        });
        title = detail.title
        content =
          (
            <MDBTable>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light" key={moment().get() * Math.random * 121}>
                  {nurseColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow1">
                  {nurseColumns.map(({ path, name }) => (
                    <td key={name}
                      className={
                        detail.data[path] && (detail.data[path] === updatedNursesData[path])
                          ? ""
                          : " bg-dark text-white"
                      }
                    >
                      {updatedNursesData[path] !== detail.data[path] ? detail.data[path] : updatedNursesData[path]}
                    </td>
                  ))}
                </tr>
                <tr key={moment().get() * Math.random * 122}>
                  {nurseColumns.map(({ path, name }) => (
                    <td key={name}>
                      {detail.data[path] === updatedNursesData[path] ? '' : updatedNursesData[path]?.toString()}
                    </td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          );
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        return;
      case "Deleting Nurse":
      case "Deleting Staff":
        title = detail.title
        selected = allStaff.filter(nurse => nurse._id === detail.data.nurseId)
        content = (
          <MDBTable>
            <MDBTableHead>
              <tr className="table th bg-danger p-3 text-light">
                {nurseColumns.map(({ path, name }) => (
                  <th key={path}>{name}</th>
                ))}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr className="tableRow1">
                {nurseColumns.map(({ path, name }) => (
                  <td key={path}>{selected[0][path]}</td>
                ))}
              </tr>
            </MDBTableBody>
          </MDBTable>
        );
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Leave Request By Staff":
      case "Leave Request By Nurse":
        LeaveData = detail.data
        Nurse = allStaff.filter(x => x?._id?.toString() === (LeaveData.staff?.toString() || LeaveData.staffId?.toString() || LeaveData.nurseId?.toString()))[0]
        LeaveData = {
          ...LeaveData,
          nurseName: Nurse?.name,
          code: Nurse?.code,
          designation: Nurse?.designation
        }
        title = detail.title
        content =
          (
            <MDBTable>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light">
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow2">
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <td key={name}>{LeaveData[path]}</td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Creating Staff Leave":
      case "Creating Nurse Leave":
        LeaveData = detail.data
        Nurse = allStaff.filter(x => x._id === (LeaveData.staff || LeaveData.nurseId))[0]
        LeaveData = {
          ...LeaveData,
          nurseName: Nurse.name,
          code: Nurse.code,
          designation: Nurse.designation
        }
        title = detail.title
        content =
          (
            <MDBTable>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light">
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow2">
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <td key={name}>{LeaveData[path]}</td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Editing Staff Leave":
      case "Editing Nurse Leave":
        LeaveData = detail.data
        Leave = Leaves.filter(x => x._id === LeaveData.leaveId)[0]
        if (!Leave) {
          return toastr.success('Leave is Successfully Updated. No Data is here to display!')
        }
        Nurse = allStaff.filter(x => x._id === Leave.staff._id)[0]
        LeaveData = {
          ...LeaveData,
          nurseName: Nurse.name,
          code: Nurse.code,
          designation: Nurse.designation
        }
        title = detail.title
        content =
          (
            <MDBTable>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light" key={moment().get() * Math.random * 142}>
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow1" key={moment().get()}>
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <td key={name}>{LeaveData[path]}</td>
                  ))}
                </tr>
                <tr
                  key={moment().get() * Math.random * 555}>
                  {NurseLeaveColumns.map(({ path, name }) => (
                    <td
                      className={
                        detail.data[path] && ((detail.data[path] === Leave[path])
                          ? ""
                          : " bg-dark text-white")
                      }
                      key={name}>
                      {detail.data[path] === Leave[path] ? '' : Leave[path]}
                    </td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Deleting Nurse Leave":
      case "Deleting Staff Leave":
        LeaveData = detail.data
        title = detail.title;
        Leave = Leaves.filter(x => x._id === LeaveData.leaveId)[0]
        if (!Leave) {
          return toastr.success('Leave is Successfully Deleted. No Data is here to display!')
        }
        Nurse = allStaff.filter(x => x._id === Leave.staff._id)[0]
        LeaveData = {
          ...LeaveData,
          ...Leave,
          nurseName: Nurse.name,
          code: Nurse.code,
          designation: Nurse.designation
        }
        content = (
          <MDBTable>
            <MDBTableHead>
              <tr className="table th bg-danger p-3 text-light" key={'1231'}>
                {NurseLeaveColumns.map(({ path, name }) => (
                  <th key={path}>{name}</th>
                ))}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr className="tableRow1" key={moment().get() * Math.random * 6666}>
                {NurseLeaveColumns.map(({ path, name }) => (
                  <td key={name}>{LeaveData[path]}</td>
                ))}
              </tr>
            </MDBTableBody>
          </MDBTable>
        );
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Assigning Nurse Rota":
        title = detail.title
        Patient = Patients.filter(x => x._id === detail.data.patientId)[0]
        needRows = detail.data.assignData
        needRows = needRows.map(x => {
          return {
            ...x,
            patient: Patient.name,
            patientCode: Patient.code
          }
        })
        content = (
          <>
            <Badge className="bg-secondary" style={{ margin: '5px', padding: '10px', fontSize: '16px' }}>{`${needRows[0]?.patientCode} - ${needRows[0]?.patient}`}</Badge>
            <MDBRow className=" bg-dark pt-2 pb-2 text-light">
              {roasterColumns.map(({ path, name }) => (
                <MDBCol key={path}>{name}</MDBCol>
              ))}
            </MDBRow>
            {needRows.map((path, index) => {
              return (
                <MDBRow className="tableRow2" style={{ height: '65px' }} key={index}>
                  {/* <MDBCol>{path.patient}</MDBCol> */}
                  <MDBCol>{allStaff.filter(x => x._id === path.nurseId).map(x => x.code)[0]}</MDBCol>
                  <MDBCol>{path.date}</MDBCol>
                  <MDBCol>{allStaff.filter(x => x._id === path.nurseId).map(x => x.name)[0]}</MDBCol>
                  <MDBCol>{allStaff.filter(x => x._id === path.nurseId).map(x => x.designation)[0]}</MDBCol>
                  <MDBCol>{path.dutyStart}</MDBCol>
                  <MDBCol>{path.dutyEnd}</MDBCol>
                  <MDBCol>{parseFloat(path.dutyStart) < 12 ? 'Morning' : 'Evening'}</MDBCol>
                  <MDBCol>{path.hour}</MDBCol>
                </MDBRow>
              );
            })}
          </>
        );

        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Creating Patient":
        Patient = detail.data;
        title = detail.title;
        content =
          (
            <MDBTable responsive>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light">
                  {patientColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow2" key={moment().get() * Math.random * 121}>
                  {patientColumns.map(({ path, name }) => (
                    <td key={path}>{Patient[path]}</td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )

        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Updating Patient":
        PatientData = detail.data;
        Patient = Patients.filter(x => x._id === PatientData.patientId)[0]
        title = detail.title
        content =
          (
            <MDBTable>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light" key={moment().get() * Math.random * 121}>
                  {patientColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow1">
                  {patientColumns.map(({ path, name }) => (
                    <td key={name}
                      className={
                        PatientData[path] && (PatientData[path] === Patient[path])
                          ? ""
                          : " bg-dark text-white"
                      }
                    >
                      {Patient[path] !== PatientData[path] ? PatientData[path] : Patient[path]}
                    </td>
                  ))}
                </tr>
                <tr key={moment().get() * Math.random * 122}>
                  {patientColumns.map(({ path, name }) => (
                    <td key={name}>
                      {PatientData[path].toString() === Patient[path].toString() ? '' : Patient[path].toString()}
                    </td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          );
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        return;
      case "Deleting Patient":
        title = detail.title
        Patient = Patients.filter(x => x._id === detail.data.patientId)[0]
        if (!Patient) {
          return toastr.success('Patient is Successfully Deleted. No Data is here to display!')
        }
        content = (
          <MDBTable>
            <MDBTableHead>
              <tr className="table th bg-danger p-3 text-light">
                {patientColumns.map(({ path, name }) => (
                  <th key={path}>{name}</th>
                ))}
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr className="tableRow1">
                {patientColumns.map(({ path, name }) => (
                  <td key={path}>{Patient[path]}</td>
                ))}
              </tr>
            </MDBTableBody>
          </MDBTable>
        );
        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Creating Holiday":
        HolidayData = detail.data;
        title = detail.title;
        content =
          (
            <MDBTable responsive>
              <MDBTableHead>
                <tr className="table th bg-dark p-3 text-light">
                  {holidayColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow2" key={moment().get() * Math.random * 121}>
                  {holidayColumns.map(({ path }) => (
                    <td key={path}>{HolidayData[path]}</td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )

        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
      case "Deleting Holiday":
        Holiday = Holidays.filter(x => x._id === detail.data.id)[0]
        if (!Holiday) {
          return toastr.success('Holiday is Successfully Deleted. No Data is here to display!')
        }
        title = detail.title;
        content =
          (
            <MDBTable responsive>
              <MDBTableHead>
                <tr className="table th bg-danger p-3 text-light">
                  {holidayColumns.map(({ path, name }) => (
                    <th key={path}>{name}</th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr className="tableRow1" key={moment().get() * Math.random * 121}>
                  {holidayColumns.map(({ path }) => (
                    <td key={path}>{Holiday[path]}</td>
                  ))}
                </tr>
              </MDBTableBody>
            </MDBTable>
          )

        setPage({
          ...page,
          notifyCollapse: true
        });

        setModal({
          badge: detail.status,
          title: title,
          content: content
        })
        break;
    }

  };

  const { isLoading, notifyCollapse } = page
  const { title, content, badge } = modal
  const { User: user, Realtime: requests, Nurses, Therapists, Patients, Leaves } = StoreData
  let allStaff = [...Nurses, ...Therapists]
  const requestFor = (detail) => {
    // console.log('requestFor', detail);
    let Nurse, Patient;
    switch (detail.title) {
      case "Deleting Staff":
      case "Deleting Nurse":
        Nurse = allStaff.find(staff => staff._id === detail.data.staff)
        if (Nurse) {
          return ` - ${Nurse.name}`;
          }
        break;
      case "Creating Staff":
      case "Updating Staff":
        Nurse = detail.data;
        if (Nurse) {
        return ` - ${Nurse.name}`;
        }
        break;
      case "Creating Patient":
      case "Updating Patient":
      case "Deleting Patient":
        Patient = Patients.find(patient => patient._id === detail.data.patientId);
        if (Patient) {
         return ` - ${Patient.name}`;
        }
        break;
      case "Editing Staff Leave":
      case "Editing Nurse Leave":
      case "Deleting Staff Leave":
      case "Deleting Nurse Leave":
       let LeaveData = detail.data
        let Leave = Leaves.filter(x => x._id?.toString() === LeaveData.leaveId?.toString())[0]
        Nurse = allStaff.filter(x => x._id === Leave?.staff?._id)[0]
        if (Nurse) {
          return ` - ${Nurse.name}`;
         }
         break;
      case "Leave Request By Staff":
      case "Leave Request By Nurse":
        Nurse = allStaff.find(nurse => nurse._id === (detail.data.staffId ? detail.data.staffId : detail.data.nurseId));
        if (Nurse) {
          return ` - ${Nurse.name}`;
        }
        break;
      case "Creating Staff Leave":
      case "Creating Nurse Leave":
        Nurse = allStaff.find(nurse => nurse._id === (detail.data.staff || detail.data.nurseId));
        if (Nurse) {
         return ` - ${Nurse.name}`;
        }
        break;
      case "Assigning Nurse Rota":
        Patient = Patients.find(patient => patient._id === detail.data.patientId);
        if (Patient) {
         return ` Patient - ${Patient.name}`;
        }
        break;
      case "Creating Holiday":
        if (detail.data) {
          return ` - ${detail.data.type}`;
        }
        break;
  }
}
  const Notifications = requests.map(request => {
    return (
      <Toast key={request._id} bg="light">
        <Toast.Header>
          {request.status === requestStatus.requested ? (
            <FaEyeSlash color="grey" />
          ) : request.status === requestStatus.approved ? (
            <FaCheck color="green" />
          ) : (
            <FaUndo color="red" />
          )}
          <strong className="me-auto">
            {request.status}
          </strong>
          <small>{moment(request.updatedAt).format('MMMM Do YYYY')}</small>
        </Toast.Header>
        <Toast.Body>
          <h6 className="text-dark">
            {request.title}
            {requestFor(request)} {user.role === userRoles.approver &&
                  <p className={`my-auto border-0 text-${request.read ? 'success' : 'danger'}`}
                  style={{fontSize: '12px'}}
                  >
                    {request?.read ? 'Read' : 'Unread'}
                  </p>}
          </h6>
          <MDBBtnGroup size="sm mx-auto">
            {(
              <>
              <MDBBtn
                outline
                color="primary"
                onClick={() => detail(request)}
              >
                Detail
              </MDBBtn>
              {user.role === userRoles.admin &&
              <p className={`ms-2 my-auto border-0 text-${request.read ? 'success' : 'danger'}`}
              style={{fontSize: '12px'}}
              >
                {request?.read ? 'Read' : 'Unread'}
              </p>}
              </>
            )}
            {
              (user.role === userRoles.approver &&
              request.status === requestStatus.requested) && (
                <>
                  <MDBBtn
                    outline
                    color="success"
                    onClick={() => approve(request)}
                  >
                    Approve
                  </MDBBtn>
                  <MDBBtn
                    outline
                    color="danger"
                    onClick={() => reject(request)}
                  >
                    Reject
                  </MDBBtn>
                </>
              )}
            {
              user.role === userRoles.user &&
              request.status !== requestStatus.requested && (
                <MDBBtn
                  outline
                  color="success"
                  onClick={() => close(request)}
                >
                  Close
                </MDBBtn>
              )}
          </MDBBtnGroup>
        </Toast.Body>
      </Toast>
    );
  }).sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt));


  return (
    <>
      <Modal
        size="lg"
        show={notifyCollapse}
        onHide={() => setPage({ ...page, notifyCollapse: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
          <Badge className={`statusBadge ${badge === requestStatus.requested ? 'bg-dark' : badge === requestStatus.approved ? 'bg-primary' : 'bg-danger'}`}>
            {badge}
          </Badge>
        </Modal.Header>
        <Modal.Body className="text-center table-responsive" style={{ width: '100%' }}>{content}</Modal.Body>
        <Modal.Footer>
          <MDBBtn
            color="dark"
            variant="secondary"
            onClick={() =>
              setPage({ ...page, notifyCollapse: false })
            }
          >
            Close
          </MDBBtn>
        </Modal.Footer>
      </Modal>


      <NavBar expand="xl" fixed="top">
        <ReactLoading
          type={"bars"}
          style={{
            display: isLoading ? "block" : "none",
            position: "fixed",
            top: "50vh",
            left: "50vw",
            width: "100px",
          }}
        />
        {User.role !== userRoles.pr &&
        <div id="sidebar_notification">
          <div className="closemenu mt-2 ml-4" onClick={menuIconClick}>
            <MDBBtn color="light" size="sm">
              <FaBell />
              {requests.length > 0 && (
                <MDBBadge className="ms-2" color="danger">
                  {requests.filter((request) => request?.read === false).length}
                </MDBBadge>
              )}
            </MDBBtn>
          </div>

          <ProSidebar collapsed={page.menuCollapse}>
            <ToastContainer className="p-3 mt-2" closebutton="false">
              {Notifications}
            </ToastContainer>
          </ProSidebar>
        </div>
          }
        <MDBContainer fluid className="px-5">
          <NavBrand className="navbar-brand" href="/dashboard" style={{ marginLeft: "-64px", background: "none !important" }}>
            {logo ?
              <img
              className="mx-auto"
                style={{ width: "fit-content", height: "40px", }}
                src={logo}
              /> :
              <img
              className="mx-auto"
                style={{ width: "40px", height: "40px", transform : "scale(1.5)" }}
                src={hospitalLogo}
              />
            }
          </NavBrand>

          <MDBNavbarToggler
            type="button"
            data-target="#navbarColor02"
            aria-controls="navbarColor02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></MDBNavbarToggler>
        {!Loading &&
          <MDBCollapse navbar >
            <MDBNavbarNav className="me-auto mb-2 mb-lg-0 d-flex">
              { User.role !== userRoles.pr && 
              <>
              <NavItem>
                <NavLink aria-current="page" to="/registration" color="primary">
                  Registration
                </NavLink>
              </NavItem>   
              <NavItem>
                <NavLink aria-current="page" to="/working-days">
                  Working Days
                </NavLink>
              </NavItem>   
              <NavItem>
                <NavLink aria-current="page" to="/leave-days">
                  Leave Days
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink aria-current="page" to="/roaster">
                  NSG-Roaster
                </NavLink>
              </NavItem>
              {
              Hospital?.modules?.includes('Therapist') &&
              <NavItem>
                <NavLink aria-current="page" to="/sessions">
                  PT-Roaster
                </NavLink>
              </NavItem>
              }
              <NavItem>
                <NavLink aria-current="page" to="/dtr">
                  DTR
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink aria-current="page" to="/fte">
                  FTE
                </NavLink>
              </NavItem>
              </>
                }
                <>
                {(user.role === userRoles.admin || user?.role === userRoles.hr || user?.role === userRoles.finance) && (
                  <NavItem>
                    <NavLink aria-current="page" to="payroll">
                      PayRoll
                    </NavLink>
                  </NavItem>
                )}
                {(user.role === userRoles.admin || user?.role === userRoles.finance) && (
                  <NavItem>
                    <NavLink aria-current="page" to="revenue">
                      Revenue
                    </NavLink>
                  </NavItem>
                )}
                {(user.role === userRoles.admin || user?.role === userRoles.finance) && (
                  <NavItem>
                    <NavLink aria-current="page" to="pnl">
                      PNL
                    </NavLink>
                  </NavItem>
                )}
              </>
              <NavItem className="ms-auto me-5" style={{cursor : "pointer"}} onClick={() => dispatch(logout())}>
                <div className="userStatus">
                <FiLogOut className="text-white" />
                  {/* <span className="admin-text text-light">Logged-in:</span> */}
                  <span className="admin text-light">{user.name}</span>
                </div>
              </NavItem>


            </MDBNavbarNav>
          </MDBCollapse>
          }
        </MDBContainer>
      </NavBar>
    </>
  );



}

export default Navbar