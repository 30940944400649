import React, { Suspense, useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar, Sidebar } from "../components/index";
import routes from "../routes";
import {
  MDBRow,
} from "mdb-react-ui-kit";

const Layout = () => {

  const storeData = useSelector((state) => state.data)
  const permissions = useMemo(() => storeData?.Role.permissions, [storeData?.Role?.permissions]) || []

 const filteredRoutes = routes.filter(
  (route) => !route.permission || permissions.includes(route.permission)
); 

// const fleetManagerRoles = ["Fleet Manager"];

// const filteredRoutes = routes.filter((route) => {
//   if (!route.permission || permissions.includes(route.permission)) {
//     if (fleetManagerRoles.includes(storeData?.User?.role)) {
//       return ["Manage Fleet", "Fuel", "Maintenance", "Expense Overview", "Vehicle Allocation"].includes(route.name);
//     } else {
//       return true;
//     }
//   }
//   return false;
// });

  return (
    <MDBRow>
        <Navbar />
      <div className="main">
      <Sidebar />
        <Suspense>
          <Routes>
            {filteredRoutes.map((route, id) => {
              return (
                route.element && (
                  <Route
                    key={id}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}
            <Route path="/" element={<Navigate to="dashboard" replace />} />
          </Routes>
        </Suspense>
      </div>
    </MDBRow>
  );
};

export default Layout;
